(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/content-reveal/assets/javascripts/content-reveal.js') >= 0) return;  svs.modules.push('/components/components/content-reveal/assets/javascripts/content-reveal.js');

'use strict';

svs.components = svs.components || {};
svs.components.ContentReveal = function (options) {
  const $el = $(options.el);
  const $toggleButton = $el.find('.js-content-reveal-toggle-button');
  const $list = $el.find('.js-content-reveal-list');
  const $items = $list.find('.js-content-reveal-list-item');
  const $toggleContainer = $el.find('.js-content-reveal-toggle');
  const enableScroll = options && options.enableScroll;
  let $nonVisibleItems;
  let animationTime = 300;
  let isOpen = false;
  let itemHeight = 0;
  let numberOfItemsOnFirstRow = 0;
  let offsetTop = 0;
  let totalHeight = 0;
  function init() {
    if (svs.core.detect.formfactor.mobile()) {
      $list.on('scroll', () => {
        $(window).trigger('scroll');
      });
    } else {
      initEvents();
      setHeight();
    }
  }
  function setHeight() {
    $items.removeClass('hide animate animate-show animate-time-short').removeAttr('style');
    const firstItem = $list.find('.js-content-reveal-list-item:visible').first();
    if (firstItem.length === 0) {
      $toggleContainer.addClass('hide');
      return;
    }
    $toggleContainer.removeClass('hide');
    itemHeight = firstItem.height();
    offsetTop = firstItem.offset().top;
    numberOfItemsOnFirstRow = getNumberOfItemsOnFirstRow();
    totalHeight = Math.ceil(getVisibleItems().length / numberOfItemsOnFirstRow) * itemHeight;
    $nonVisibleItems = getItemsOutOfViewport();
    if (isOpen) {
      $list.css({
        height: totalHeight
      });
    } else {
      $list.css({
        height: itemHeight
      });

      animationTime = Math.round($nonVisibleItems.length * 50 + 250, 10);
      $nonVisibleItems.addClass('hide animate animate-show animate-time-short').each(function (i) {
        $(this).css({
          'animation-delay': "".concat(Math.round((i + 1) * (animationTime / $nonVisibleItems.length)), "ms")
        });
      });
    }
    if ($nonVisibleItems.length === 0) {
      $toggleContainer.addClass('hide');
    }
  }
  function getVisibleItems() {
    return $list.find('.js-content-reveal-list-item:not(.content-reveal-list-item-hidden):not(.content-reveal-list-item-filler)');
  }
  function getNumberOfItemsOnFirstRow() {
    return getVisibleItems().filter(function () {
      return $(this).offset().top === offsetTop;
    }).length;
  }
  function getItemsOutOfViewport() {
    let numberOfHiddenItems = 0;
    return $list.find('.js-content-reveal-list-item:not(.content-reveal-list-item-filler)').filter(function (i) {
      if ($(this).hasClass('content-reveal-list-item-hidden') && i < numberOfItemsOnFirstRow) {
        numberOfHiddenItems++;
      }
      return $(this).hasClass('content-reveal-list-item-hidden') === false && i >= numberOfItemsOnFirstRow + numberOfHiddenItems;
    });
  }
  function initEvents() {
    $toggleButton.on('click', onClick);
    $(window).on('resize', onResize);
  }
  function onResize() {
    setHeight();
  }
  function onClick() {
    if (isOpen) {
      isOpen = false;
      $list.animate({
        height: itemHeight
      }, animationTime, () => {
        $nonVisibleItems.addClass('hide');
      });
      $toggleButton.html('Visa fler <span class="content-reveal-safe-font">&#43;</span>');
    } else {
      isOpen = true;
      if ($el.parents('.js-swiper-container').length === 0) {
        enableScroll && $('html, body').stop().animate({
          scrollTop: $el.offset().top - svs.core.detect.dimensions.offsetTop
        }, animationTime);
      }
      $list.animate({
        height: totalHeight
      }, animationTime);
      $nonVisibleItems.removeClass('hide');
      $toggleButton.html('Visa färre <span class="content-reveal-safe-font">&#8722;</span>');
    }
  }
  function destroy() {
    $list.off('scroll');
    $toggleButton.off('click');
    $(window).off('resize', onResize);
  }
  init();
  return {
    resize() {
      setHeight();
    },
    destroy
  };
};

 })(window);